import { useState } from 'react';
import { 
    useTheme, 
    useMediaQuery, 
    Box, 
    Tooltip, 
    Chip, 
    IconButton,
    Button,
} from '@mui/material';
import {
    StarRounded,
    StarBorderRounded,
    EditRounded,
    HighlightOffRounded,
    QrCode2Rounded,
    DeleteRounded,
    DeleteForeverRounded,
    CancelRounded,
} from '@mui/icons-material';
import { withPather } from 'react-pather';
import EditModal from './EditModal';
import { 
    Confirmation, 
    QRCodeModal 
} from '../../general';

const ConversionHead = ({ pather, ...props }) => {

    const {
        conversion, 
        toggleFavourite, 
        successDeactivation, 
        edit, 
        successForget, 
        advanced, 
        setAdvancedSafe,
        handleDeleteForever,
    } = props;

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    const [delConfirmOpen, setDelConfirmOpen] = useState(false);
    const [forgetConfirmOpen, setForgetConfirmOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [openQRCodeModal, setOpenQRCodeModal] = useState(false);
    const [openConfirmDeleteForever, setOpenConfirmDeleteForever] = useState(false);

    return (
        <Box sx={{
            mt: 2, 
            mb: 3, 
            display: 'flex', 
            justifyContent: 'start', 
            alignItems: conversion.invalidAt ? 'center' : 'baseline', 
            flexDirection: matches ? 'column': 'row',
            gap: {xs: 2, md: 0}
        }}>
            <Box sx={{display: 'flex', width: {xs: '100%', md: 'initial'}, alignItems: 'center', gap: {xs: 1.5, md: 3}}}>
                <Box sx={{display: 'flex', fontWeight: 'bold', fontSize: '2rem', flexDirection: {xs: 'column', sm: 'row'}}}>
                    <Box>Conversion&nbsp;</Box>
                    <Box sx={{
                        maxWidth: {xs: '50vw', sm: '45vw', md: '20vw', xl: '15vw'}, 
                        overflowX: 'auto', 
                        textDecoration: 'underline',
                        whiteSpace: 'nowrap',
                        colorScheme: theme.palette.mode === 'dark' ? 'dark' : 'light',
                    }}>
                        {conversion.code}
                    </Box>
                </Box>
                {
                    conversion.markers.isActive ? 
                    <Chip label="Active" color="success" sx={{color: 'white'}}/> : 
                    <>
                        {   
                            conversion.markers.isExpired ?
                            <Chip label="Expired" color="error"/> :
                            <>
                                {
                                    conversion.markers.isDeactivated ?
                                    <Chip label="Deactivated" color="error"/> :
                                    <>
                                        {
                                            conversion.markers.isForgotten ?
                                            <Chip label="Forgotten" color="error"/> :
                                            <Chip label="Inactive" color="error"/> 
                                        }
                                    </>
                                }
                            </>
                        }
                    </>
                }
            </Box>
            {   
                conversion.markers.isForgotten ?
                <Box sx={{ml: {md: 1}, display: 'flex', alignSelf: {xs: 'start', md: 'center'}}}>
                    <Box>
                        <Box>
                            <Tooltip title="Delete">
                                <IconButton onClick={() => setOpenConfirmDeleteForever(true)}>
                                    <DeleteForeverRounded />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box> :
                <Box sx={{ml: {md: 1}, display: 'flex', alignSelf: {xs: 'start', md: 'center'}}}>
                    <Box>
                        <Tooltip title="Favourite">
                            <IconButton onClick={toggleFavourite}>
                                {   
                                    conversion.isFavourite ?
                                    <StarRounded /> :
                                    <StarBorderRounded />
                                }
                            </IconButton>
                        </Tooltip>
                    </Box>
                    {   
                        conversion.markers.isActive &&
                        <Box>
                            <Tooltip title="QR code">
                                <IconButton onClick={() => setOpenQRCodeModal(true)}>
                                    <QrCode2Rounded />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    }
                    <Box>
                        <Tooltip title="Edit">
                            <IconButton onClick={() => setEditModalOpen(true)}>
                                <EditRounded/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                    {   
                        conversion.markers.isActive &&
                        <Box>
                            <Tooltip title="Deactivate">
                                <IconButton onClick={() => setDelConfirmOpen(true)}>
                                    <HighlightOffRounded/>
                                </IconButton>
                            </Tooltip>
                        </Box>
                    }
                    <Box>
                        <Tooltip title="Forget">
                            <IconButton onClick={() => setForgetConfirmOpen(true)}>
                                <DeleteRounded/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            }
            <Box sx={{marginLeft: {md: 'auto'}, cursor: 'pointer', display: 'flex', flexDirection: 'column', alignSelf: {xs: 'start', md: 'initial'}}}>
                <Tooltip title="Creation date" placement="top">
                    <span>
                        {conversion.createdAt}
                    </span>
                </Tooltip>
                {   
                    conversion.invalidAt &&
                    <Tooltip title="Expiration date">
                        <span>
                            {conversion.invalidAt}
                        </span>
                    </Tooltip>
                }
            </Box>
            <Confirmation 
                open={forgetConfirmOpen}
                setOpen={setForgetConfirmOpen} 
                title='Confirm forgetting' 
                description={
                    <>
                        Are you sure you want to forget conversion with a code <b>{conversion.code}</b>? 
                        { conversion.markers.isActive ? " You won't be able to activate it again.": "" }
                    </>
                }
                success={successForget}
            />
            <Confirmation 
                open={delConfirmOpen}
                setOpen={setDelConfirmOpen} 
                title='Confirm deactivation' 
                description={
                    <>
                        Are you sure you want to deactivate conversion with a code <b>{conversion.code}</b>? 
                        You won't be able to activate it again.
                    </>
                }
                success={successDeactivation}
            />
            <Confirmation 
                open={openConfirmDeleteForever}
                setOpen={setOpenConfirmDeleteForever}
                title="Conversion deletion"
                description={
                    <>
                        Are you sure you want to delete conversion with a code <b>{conversion.code}</b> forever? 
                        This action <b>cannot</b> be reverted.
                    </>
                }
                success={() => handleDeleteForever(conversion.code)}
            />
            <EditModal 
                open={editModalOpen}
                setOpen={setEditModalOpen}
                conversion={conversion}
                handleEdit={edit}
                advanced={advanced}
                setAdvancedSafe={setAdvancedSafe}
            />
            <QRCodeModal 
                open={openQRCodeModal} 
                handleClose={() => setOpenQRCodeModal(false)} 
                value={`${window.location.origin}${pather.reverse(pather.front.main, { code: conversion.code })}`}
            />
        </Box>
    );
}

export default withPather()(ConversionHead);