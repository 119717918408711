import {
    Button,
    ButtonGroup,
} from '@mui/material';

const ModeSwitcher = ({ mode, setMode }) => {
    return (
        <ButtonGroup
            disableElevation
            sx={{width: '100%'}}
        >
            <Button 
                sx={{
                    borderTopLeftRadius: '20px', borderEndStartRadius: '20px', width: {xs: '100%', md: '100%'}, p: 1,
                    fontWeight: mode === 'history' && 'bold',
                }} 
                onClick={() => setMode('history')}
                variant={mode === 'history' ? 'contained' : 'outlined'}
            >
                History
            </Button>
            <Button 
                sx={{
                    borderTopRightRadius: '20px', borderEndEndRadius: '20px', width: {xs: '100%', md: '100%'}, p: 1,
                    fontWeight: mode === 'favourites' && 'bold',
                }} 
                onClick={() => setMode('favourites')}
                variant={mode === 'favourites' ? 'contained' : 'outlined'}
            >
                Favourites
            </Button>
        </ButtonGroup>
    );
}

export default ModeSwitcher;