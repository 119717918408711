import Box from '@mui/material/Box';
import { Drawer as MUIDrawer } from '@mui/material';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { Icon } from '@iconify/react';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import { useDispatch } from 'react-redux';
import { toggleTheme } from '../../slices/themeSlice';
import { withPather } from 'react-pather';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import ShortcutRoundedIcon from '@mui/icons-material/ShortcutRounded';
import IconButton from '@mui/material/IconButton';
import PersonAddRoundedIcon from '@mui/icons-material/PersonAddRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import { roleIcon } from '../../utils';

const Drawer = ({ pather, open, toggleDrawer, user, logout }) => {

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const icon = roleIcon();

    const list = (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                <Box sx={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1}}>
                    <Box 
                        className="noselect"
                        sx={{ 
                            fontFamily: 'Emblema One', 
                            color: theme.palette.text.main, 
                            fontSize: 22,
                            cursor: 'pointer'
                        }}
                        onClick={() => navigate('/')}
                    >
                        <h1>Shortl</h1>
                    </Box>
                    <Box>
                        <IconButton onClick={() => dispatch(toggleTheme())} sx={{cursor: 'pointer'}}>
                            { 
                                theme.palette.mode === 'dark' ?
                                <LightModeRoundedIcon fontSize='large' /> :
                                <DarkModeRoundedIcon fontSize='large' />
                            }
                        </IconButton> 
                    </Box>
                </Box>
                {
                    user?.isLogined &&
                    <>
                        <Divider sx={{mb: 2}}/>
                        <Box sx={{
                            display: 'flex', width: '100%', 
                            height: '100%', justifyContent: 'center', 
                            alignItems: 'center', fontSize: '1.5rem',
                            mb: 2, gap: 1, 
                        }}>
                            <Box sx={{overflow: 'auto'}}>  
                                {user.username}
                            </Box>    
                            {
                                icon && 
                                <Box>
                                    { icon }
                                </Box>
                            }
                        </Box>
                    </>
                }
                <Divider sx={{mb: 2}}/>
                {
                    !user.isLogined && location.pathname !== pather.front.shorten &&
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate(pather.front.shorten)}>
                            <ListItemIcon>
                                <ShortcutRoundedIcon fontSize='large'/>
                            </ListItemIcon>
                            <ListItemText primary='Shorten' />
                        </ListItemButton>
                    </ListItem>
                }
                {
                    !user?.isLogined && location.pathname !== pather.front.login && 
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate(pather.front.login)}>
                            <ListItemIcon>
                                <LoginRoundedIcon fontSize='large'/>
                            </ListItemIcon>
                            <ListItemText primary='Login' />
                        </ListItemButton>
                    </ListItem>
                }
                {
                    !user?.isLogined && location.pathname !== pather.front.signup && 
                    <ListItem disablePadding>
                        <ListItemButton onClick={() => navigate(pather.front.signup)}>
                            <ListItemIcon>
                                <PersonAddRoundedIcon fontSize='large' />
                            </ListItemIcon>
                            <ListItemText primary='Sign up' />
                        </ListItemButton>
                    </ListItem>
                }
                {
                    user?.isLogined &&
                    <>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigate(pather.front.dashboard)}>
                                <ListItemIcon>
                                    <DashboardRoundedIcon fontSize='large'/>
                                </ListItemIcon>
                                <ListItemText primary='Dashboard' />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigate(pather.front.shorten)}>
                                <ListItemIcon>
                                    <ShortcutRoundedIcon fontSize='large'/>
                                </ListItemIcon>
                                <ListItemText primary='Shorten' />
                            </ListItemButton>
                        </ListItem>
                        {/* <ListItem disablePadding>
                            <ListItemButton onClick={() => {}}>
                                <ListItemIcon>
                                    <PaidIcon fontSize='large'/>
                                </ListItemIcon>
                                <ListItemText primary='Plan' />
                            </ListItemButton>
                        </ListItem> */}
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => navigate(pather.front.settings)}>
                                <ListItemIcon>
                                    <SettingsIcon fontSize='large'/>
                                </ListItemIcon>
                                <ListItemText primary='Settings' />
                            </ListItemButton>
                        </ListItem> 
                        <ListItem disablePadding>
                            <ListItemButton onClick={logout}>
                                <ListItemIcon>
                                    <LoginRoundedIcon fontSize='large'/>
                                </ListItemIcon>
                                <ListItemText primary='Log out' />
                            </ListItemButton>
                        </ListItem>
                    </>
                }
            </List>
        </Box>
    );

    return (
        <Box>
            <MUIDrawer
                anchor='left'
                open={open}
                onClose={toggleDrawer(false)}
            >
                {list}
            </MUIDrawer>
        </Box>
    );
}

export default withPather()(Drawer);