import { 
    Button,
    ButtonGroup,
} from '@mui/material';
import { 
    BarChartRounded,
    TimelineRounded, 
    PieChartRounded,
} from '@mui/icons-material';
import { 
    redirectsViewModes, 
    redirectsChartTypes, 
} from '.';
import PerPageSwitcher from '../../general/ConversionListViewer/ConversionListSettings/PerPageSwitcher';

const RedirectsHistoryTopBarSettings = props => {
    const {
        redirectsViewMode,
        redirectsPerPage,
        handleRedirectsPerPageChange,
        redirectsChartType,
        setRedirectsChartType,
    } = props;
    return (
        <>
            {
                redirectsViewMode === redirectsViewModes.history ?
                <PerPageSwitcher
                    perPage={redirectsPerPage}
                    handlePerPageChange={handleRedirectsPerPageChange}
                    variant="standard"
                    inputText=""
                /> : 
                <ButtonGroup 
                    variant="outlined" 
                    aria-label="Chart types button group" 
                    size="small"
                >
                    <Button
                        variant={redirectsChartType === redirectsChartTypes.bar ? 'contained' : 'outlined'}
                        onClick={() => setRedirectsChartType(redirectsChartTypes.bar)}
                    >
                        <BarChartRounded/>
                    </Button>
                    <Button
                        variant={redirectsChartType === redirectsChartTypes.timeline ? 'contained' : 'outlined'}
                        onClick={() => setRedirectsChartType(redirectsChartTypes.timeline)}
                    >
                        <TimelineRounded/>
                    </Button>
                    <Button
                        variant={redirectsChartType === redirectsChartTypes.pie ? 'contained' : 'outlined'}
                        onClick={() => setRedirectsChartType(redirectsChartTypes.pie)}
                    >
                        <PieChartRounded/>
                    </Button>
                </ButtonGroup>
            }
        </>
    );
}

export default RedirectsHistoryTopBarSettings;