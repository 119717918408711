import { 
    useState, 
    useEffect 
} from 'react';
import { 
    useSelector, 
    useDispatch,
} from 'react-redux';
import { 
    useNavigate, 
    useParams, 
} from 'react-router-dom';
import { 
    Container,
    Box, 
} from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { withPather } from 'react-pather';
import ConversionHead from './ConversionHead';
import Details from './Details';
import RedirectHistory from './RedirectHistory';
import LinkDisplay from './LinkDisplay';
import Loader from '../../Loader';
import { PasswordField } from '../../general';
import { setConversion } from '../../../slices/conversionSlice';

const ConversionView = ({ pather }) => {

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { code } = useParams();

    // General
    const conversion = useSelector(state => state.conversion.value);
    
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    // Passcode visibility
    const [showPasscode, setShowPasscode] = useState(false);
    const handleClickShowPasscode = () => setShowPasscode(show => !show);

    // Storing Advanced settings
    const [advanced, setAdvanced] = useState({
        maxRedirects: conversion.maxRedirects,
        revealable: conversion.revealable,
        isDead: conversion.isDead,
        passcode: conversion.passcode,
    });

    // Safe update
    const setAdvancedSafe = (updStateObj) => {
        setAdvanced((prevState) => ({
            ...prevState,
            ...updStateObj,
        }));    
    }
    //

    useEffect(() => {
        setAdvancedSafe({
            maxRedirects: conversion.maxRedirects,
            revealable: conversion.revealable,
            isDead: conversion.isDead,
            passcode: conversion.passcode,
        })
    }, [conversion])

    useEffect(() => {
        // \/ TODO: Stale data may exist in Redux (Redux Conversion Data are not up to date after Conversion update)
        // if(conversion.code && conversion.code === code) return; 
        setLoading(true);
        axios.get(
            pather.reverse(pather.back.Conversion.code, { code }),   
        )
        .then(response => {
            const conversion = response?.data?.conversion;
            if (conversion?.code) dispatch(setConversion(conversion));
        })
        .catch(error => {
            console.log(error);
            const status = error?.data?.status;
            if(status) setError(status);
            else {
                if (error?.response?.status === 404) setError('Resource is not found!');
                else setError('Something went wrong!');
            }
        })
        .finally(() => {
            setLoading(false);    
        });
    }, [])

    const toggleFavourite = () => {
        setLoading(true);
        axios.post(
            pather.reverse(pather.back.Conversion.favourite, { code: conversion.code })
        )
        .then(response => {
            const conversion = response?.data?.conversion;
            if (conversion?.code) dispatch(setConversion(conversion));
        })
        .catch(err => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const successDeactivation = () => {
        setLoading(true);
        axios.post(
            pather.reverse(pather.back.Conversion.deactivateConv, { code }),
        )
        .then(response => {
            const conversion = response?.data?.conversion;
            if (conversion?.code) dispatch(setConversion(conversion));
        })
        .catch(err => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const isInputValid = () => {
        if ((advanced.maxRedirects != null && advanced.maxRedirects <= 0) || (advanced.maxRedirects != null && isNaN(parseInt(advanced.maxRedirects)))) {
            enqueueSnackbar('Max redirects number must be bigger that 0!', { variant: 'error' });
            return false;
        }
        if (advanced.passcode !== conversion.passcode){
            if (advanced.passcode && advanced.passcode.length < 5){
                enqueueSnackbar('Passcode must contain at least 5 characters!', { variant: 'error' });
                return false;
            }
        }
        return true;
    }

    const edit = () => {
        setLoading(true);
        if (!isInputValid()) {
            setLoading(false);
            return;
        }
        axios.put(
            pather.reverse(pather.back.Conversion.code, { code }), 
            {
                source: conversion.source,
                ...advanced, 
            }
        )
        .then(response => {
            const conversion = response?.data?.conversion;
            if (conversion?.code) dispatch(setConversion(conversion));
        })
        .catch(err => {
            const status = err?.response?.data?.status;
            if (!!status) {
                enqueueSnackbar(status, { variant: 'error' });
                setAdvancedSafe({ // TODO: how good is it?
                    maxRedirects: conversion.maxRedirects,
                })
            }
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const successForget = () => {
        setLoading(true);
        axios.put(pather.reverse(pather.back.Conversion.forget, { code }))
        .then(response => {
            const data = response?.data;
            const conversion = data.conversion;
            if (conversion) dispatch(setConversion(conversion));
            if (data?.status) enqueueSnackbar(data.status, { variant: 'success' });
        })
        .catch(err => {
            const data = err?.response?.data;
            if (data?.status) {
                enqueueSnackbar(data.status, { variant: 'error' });
                setAdvancedSafe({
                    maxRedirects: conversion.maxRedirects,
                })
            }
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const handleDeleteForever = code => {
        setLoading(true);
        axios.delete(
            pather.reverse(pather.back.Conversion.delete, { code })
        ).then(response => {
            const data = response.data;
            const status = data?.status;
            status && enqueueSnackbar(status, { variant: 'success' });
            navigate(-1);  // Navigate to previous page
        })
        .catch(error => {
            console.log(error);
            const status = error?.response?.data?.status;
            status && enqueueSnackbar(status, { variant: 'error' });
        })
        .finally(() => {
            setLoading(false);
        });
    }

    return (
        <Container sx={{height: '100%'}}>
            {
                loading || (!conversion?.code && !error) ? 
                <Loader/> :
                <>
                    {
                        error ? 
                        <Box sx={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', minHeight: '320px'}}>
                            <h1>{error} &#128546;</h1>
                        </Box>:
                        <Box>
                            <Box sx={{m: 2, fontSize: {md: 16, xs: 12}, width: '100%', display: 'flex', flexDirection: 'column'}}>     
                                <ConversionHead 
                                    conversion={conversion} 
                                    toggleFavourite={toggleFavourite}
                                    successDeactivation={successDeactivation}
                                    edit={edit}
                                    advanced={advanced}
                                    setAdvancedSafe={setAdvancedSafe}
                                    successForget={successForget}
                                    handleDeleteForever={handleDeleteForever}
                                />
                                <Box sx={{width: {xs: '86%', sm: '93%', md: '97%'}}}>
                                    <LinkDisplay 
                                        link={`${window.location.origin}${pather.reverse(pather.front.main, { code: conversion.code })}`}
                                        label='Shortened link: '
                                    />
                                </Box>
                                <Box sx={{width: {xs: '86%', sm: '93%', md: '97%'}}}>
                                    <LinkDisplay 
                                        link={conversion.source}
                                        label='Source link: '
                                    />
                                </Box>
                                {
                                    conversion.passcode &&
                                    <Box sx={{width: {xs: '93%', sm: '95%', md: '99%'}, mb: 3}}>
                                        <Box>
                                            <h3>Passcode: </h3>
                                        </Box>
                                        <PasswordField
                                            value={conversion.passcode}
                                            show={showPasscode}
                                            handleShow={handleClickShowPasscode}
                                            style={{width: {xs: '100%', sm: '40vw', md: '30vw'}}}
                                        />
                                    </Box>
                                }
                                <Details conversion={conversion} />
                                <RedirectHistory code={code} />
                            </Box>
                        </Box>
                    }
                </>
            }
        </Container>
    );
}

export default withPather()(ConversionView);