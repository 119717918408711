import * as sections from './sections';
import { 
    BACKEND_URL, 
    BACKEND_API_ENDPOINT 
} from "../../config";

// Route names must be unique and start with /
const routes = {
    backend: {
        path: BACKEND_URL,
        sub: {
            api: {
                path: BACKEND_API_ENDPOINT,
                sub: {
                    rating: '/rating', 
                    confirmAccount: '/confirm/:uut',  
                    confirmationLink: '/confirmation-link', 
                    conversion: sections.Conversion,
                    conversions: '/conversions',  
                    settings: sections.Settings,
                    auth: sections.Auth,
                    reset: sections.Reset,
                }
            },
            admin: '/admin', 
        }
    },
}

export default routes;