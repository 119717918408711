import { 
    Box, 
    IconButton,
    MenuItem,
    Select,
} from '@mui/material';
import { UpdateRounded } from '@mui/icons-material';
import { redirectsViewModes } from '.';
import RedirectsHistoryTopBarSettings from './RedirectsHistoryTopBarSettings';

const RedirectsHistoryTopBar = props => {
    const {
        redirectsNumOfResults,
        reload,
        redirectsViewMode,
        setRedirectsViewMode,
        matchesRedirectsHistoryOnly,
        ...otherProps
    } = props;
    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}> 
            <Box>
                {
                    matchesRedirectsHistoryOnly ?
                    <h2>
                        Redirect History ({ redirectsNumOfResults })
                    </h2> :
                    <Select
                            variant='standard'
                            id="select-redirects-view-mode"
                            value={redirectsViewMode}
                            onChange={setRedirectsViewMode}
                            disableUnderline
                            renderValue={
                                () => redirectsViewMode === redirectsViewModes.history ? 
                                `Redirect History (${redirectsNumOfResults})` :
                                'Redirect Chart'
                            }
                            sx={{
                                minWidth: '200px',
                                fontSize: '24px',
                                fontWeight: 'bold',
                            }}
                        >
                        <MenuItem value='history'>
                            Redirect History
                        </MenuItem>
                        <MenuItem value='chart'>
                            Redirect Chart
                        </MenuItem>
                    </Select>
                }
            </Box>
            <Box>
                <IconButton onClick={reload}>
                    <UpdateRounded/>
                </IconButton>
            </Box>
            <Box sx={{marginLeft: 'auto'}}>
                <RedirectsHistoryTopBarSettings
                    redirectsViewMode={redirectsViewMode} 
                    {...otherProps}
                />
            </Box>
        </Box>
    );
}

export default RedirectsHistoryTopBar;