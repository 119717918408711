import { 
    useState, 
    useEffect, 
} from "react";
import {
    useNavigate, 
    useParams, 
} from "react-router-dom";
import { 
    Container, 
    Box, 
    Typography,
    Button,
} from "@mui/material";
import { 
    usePather, 
    withPather, 
} from "react-pather";
import axios from "axios";
import { useSnackbar } from "notistack";
import Loader from "../Loader";
import { PasswordField } from "../general";


const ReturnToLogin = ({ message }) => {
    const navigate = useNavigate();
    const pather = usePather();
    return (
        <>
            <Typography
                component="div"
                sx={{
                    fontSize: '32px'
                }}
                gutterBottom
            >
               { message }
            </Typography>
            <Button 
                variant="contained" 
                sx={{pt: 1, pb: 1, pl: 3, pr: 3}}
                onClick={() => navigate(pather.front.login)}
            >
                Return to Login
            </Button>
        </>
    );
}

const Reset = ({ pather }) => {

    const { enqueueSnackbar } = useSnackbar();
    const { token } = useParams();
    const [loading, setLoading] = useState();
    const [allowChange, setAllowChange] = useState(false);
    const [isChanged, setIsChanged] = useState(false);

    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');

    // Password visibility
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showNewPasswordConfirmation, setShowNewPasswordConfirmation] = useState(false);

    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
    const handleClickShowNewPasswordConfirmation = () => setShowNewPasswordConfirmation((show) => !show);
    //

    useEffect(() => {
        setLoading(true);
        axios.post(pather.reverse(pather.back.Reset.verify, { token }))
        .then(response => {
            setAllowChange(true);
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }, []);

    const arePasswordsValid = () => {
        if (!newPassword){
            enqueueSnackbar('New password is not provided!', { variant: 'error' });
            return false;
        }
        else if (newPassword.length < 8){
            enqueueSnackbar('Password must contain at least 8 characters!', { variant: 'error' });
            return false;
        }
        else if (newPassword.includes(' ')) {
            enqueueSnackbar('Password can not include whitespace!', { variant: 'error' });
            return false;
        }
        else if (!newPasswordConfirmation){
            enqueueSnackbar('Password is not confirmed!', { variant: 'error' });
            return false;
        }
        else if (newPassword !== newPasswordConfirmation){
            enqueueSnackbar('Passwords do not match!', { variant: 'error' });
            return false;
        }
        return true;
    }

    const changePassword = () => {
        if(!arePasswordsValid()) return;

        setLoading(true);
        axios.post(
            pather.reverse(pather.back.Reset.reset, { token }), 
            { newPassword }
        )
        .then(() => {
            setIsChanged(true);
        })
        .catch(error => {
            const response = error?.response;
            if (response?.status === 400 && response?.data?.status){
                enqueueSnackbar(response?.data?.status, { variant: 'error' });
            }
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    return (
        <Container sx={{height: '100%'}}>
            {
                loading ? 
                <Loader/> :
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3, mt: {xs: 5, sm: 15}, mb: {xs: 5, sm: 20}}}>
                    {                        
                        allowChange ?
                        <>
                            {
                                isChanged ?
                                <ReturnToLogin 
                                    message='Password was successfully changed'
                                /> :   
                                <>
                                    <Typography
                                        component="div"
                                        sx={{
                                            fontSize: '32px'
                                        }}
                                        gutterBottom
                                    >
                                        Set New Password
                                    </Typography>
                                    <Box sx={{display: 'flex', flexDirection: 'column', gap: 2, width: {xs: '100%', sm: '70%', md: '30vw'}}}>
                                        <Box sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                                            <PasswordField
                                                label='New password'
                                                value={newPassword} 
                                                handleChange={e => setNewPassword(e.target.value)} 
                                                show={showNewPassword} 
                                                handleShow={handleClickShowNewPassword}
                                            />
                                            <PasswordField
                                                label='Confirm new password'
                                                value={newPasswordConfirmation} 
                                                handleChange={e => setNewPasswordConfirmation(e.target.value)} 
                                                show={showNewPasswordConfirmation} 
                                                handleShow={handleClickShowNewPasswordConfirmation}
                                                error={newPasswordConfirmation && newPassword !== newPasswordConfirmation }
                                                helperText={newPasswordConfirmation && newPassword !== newPasswordConfirmation  && "Passwords do not match"}
                                            />
                                        </Box>
                                        <Button 
                                            variant="contained" 
                                            sx={{width: '100%', pt: 1, pb: 1}}
                                            onClick={changePassword}
                                        >
                                            Change Password
                                        </Button>
                                    </Box>
                                </>
                            }
                        </> :
                        <ReturnToLogin 
                            message='Current link is not active anymore &#128546;'
                        />
                    }
                </Box>
            }
        </Container>
    );
}

export default withPather()(Reset);