import {
    useState,
    useEffect,
    memo,
} from 'react';
import { 
    useTheme,
    useMediaQuery,
    Box,
    Pagination,
} from '@mui/material';
import axios from 'axios';
import { withPather } from 'react-pather';
import RedirectsHistoryTopBar from './RedirectsHistoryTopBar';
import RedirectHistoryRow from './RedirectHistoryRow';
import RedirectHistoryChart from './RedirectHistoryChart';
import NoData from './NoData';
import { 
    PeriodOptions, 
    periodOptions, 
} from '../../settings/tabs/common';
import Loader from '../../../Loader';
import { useEffectExceptOnMount } from '../../../../hooks';

const redirectsViewModes = {
    history: 'history',
    chart: 'chart',
}

const redirectsChartTypes = {
    timeline: 'timeline',
    pie: 'pie',
    bar: 'bar',
}

const PREFIX = 'view-conv-';
const REDIRECTS_VIEW_MODE = `${PREFIX}redirects-view-mode`;
const REDIRECTS_CHART_TYPE = `${PREFIX}redirects-chart-type`;

const RedirectHistory = memo(({ pather, code }) => {
    
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesDownLg = useMediaQuery(theme.breakpoints.down('lg'));

    const [error, setError] = useState('');
    const [redirectsLoading, setRedirectsLoading] = useState(false);

    const [redirects, setRedirects] = useState([]);
    const [redirectsOffset, setRedirectsOffset] = useState(0);
    const [redirectsPerPage, setRedirectsPerPage] = useState(25);
    const [redirectsTotalPages, setRedirectsTotalPages] = useState(0);
    const [redirectsNumOfResults, setRedirectsNumOfResults] = useState(0);

    const [redirectsViewMode, setRedirectsViewMode] = useState(redirectsViewModes.history);
    const [redirectsChartType, setRedirectsChartType] = useState(redirectsChartTypes.bar);
    const [chartPeriod, setChartPeriod] = useState(periodOptions[0]);
    const [series, setSeries] = useState([]);
    const [labels, setLabels] = useState([]);
    const [availablePeriodOptions, setAvailablePeriodOptions] = useState([]);

    const fetchRedirects = (success = null) => {
        setRedirectsLoading(true);
        axios.get(
            pather.reverse(
                pather.back.Conversion.redirects, 
                { code },
            ),
            {
                params: {
                    offset: redirectsOffset,
                    perPage: redirectsPerPage,
                }, 
            }   
        )
        .then(response => {
            const data = response?.data;
            setRedirects(data?.redirects);
            setRedirectsTotalPages(data?.total_pages);
            setRedirectsNumOfResults(data?.number_of_results);
            success && success();
        })
        .catch(error => {
            console.log(error);
            const status = error?.data?.status;
            if(status) setError(status);
            else {
                if (error?.response?.status === 404) setError('Resource is not found!');
                else setError('Something went wrong!');
            }
        })
        .finally(() => {
            setRedirectsLoading(false);
        });
    }

    const fetchChartData = () => {
        setRedirectsLoading(true);
        axios.get(
            pather.reverse(
                pather.back.Conversion.redirectsChart, 
                { code },
            ),
            {
                params: {
                    chartType: redirectsChartType,
                    chartPeriod: chartPeriod.value,
                }, 
            }   
        )
        .then(response => {
            const data = response?.data;
            setLabels(data?.labels);
            setSeries(data?.series);
            setRedirectsNumOfResults(data?.number_of_results);
            setAvailablePeriodOptions(data?.period_options);
        })
        .catch(error => {
            console.log(error);
            const status = error?.data?.status;
            if(status) setError(status);
            else setError('Something went wrong!');
        })
        .finally(() => {
            setRedirectsLoading(false);
        });
    }

    useEffect(() => {
        fetchRedirects();
        const storedRedirectsViewMode = localStorage.getItem(REDIRECTS_VIEW_MODE);
        if (storedRedirectsViewMode) setRedirectsViewMode(storedRedirectsViewMode);
        const storedRedirectsChartType = localStorage.getItem(REDIRECTS_CHART_TYPE);
        if (storedRedirectsChartType) setRedirectsChartType(storedRedirectsChartType);
    }, []);

    useEffectExceptOnMount(() => {
        fetchRedirects();
    }, [redirectsOffset]);

    useEffectExceptOnMount(() => {
        fetchRedirectsWithOffsetReset();
    }, [redirectsPerPage]);

    useEffectExceptOnMount(() => {
        clean();
        redirectsViewMode === redirectsViewModes.history ?
        fetchRedirectsWithOffsetReset() : 
        fetchChartData();
    }, [redirectsViewMode]);

    useEffectExceptOnMount(() => {
        clean(); 
        fetchChartData();
    }, [redirectsChartType, chartPeriod])

    const clean = () => {
        setLabels([]);
        setSeries([]);
        setRedirectsNumOfResults(0);
        setAvailablePeriodOptions([]);
    }

    const fetchRedirectsWithOffsetReset = () => {
        fetchRedirects(() => {
            setRedirectsOffset(0);
        });
    }

    const reload = () => {
        clean();
        redirectsViewMode === redirectsViewModes.history ?
        fetchRedirectsWithOffsetReset() :
        fetchChartData();
    }

    const handleOffsetChange = (event, value) => setRedirectsOffset(value - 1);
    const handleRedirectsPerPageChange = event => setRedirectsPerPage(event.target.value);

    const setRedirectsViewModeLS = ({ target: { value } }) => {
        setRedirectsViewMode(value);
        localStorage.setItem(REDIRECTS_VIEW_MODE, value);
    }

    const setRedirectsChartTypeLS = value => {
        setRedirectsChartType(value);
        localStorage.setItem(REDIRECTS_CHART_TYPE, value);
    }

    if (matchesDownLg && redirectsViewMode !== redirectsViewModes.history) {
        setRedirectsViewMode(redirectsViewModes.history);
    }

    return (
        <>
            {
                redirectsLoading ? 
                <Loader/> :
                <>
                    {   
                        error ? 
                        <Box sx={{
                            display: 'flex', width: '100%', justifyContent: 'center', 
                            alignItems: 'center', minHeight: '320px'
                        }}>
                            <h3>{error} &#128546;</h3>
                        </Box> :
                        <>
                            <Box sx={{mt: 2, width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: 1}}>
                                <RedirectsHistoryTopBar
                                    redirectsNumOfResults={redirectsNumOfResults}
                                    reload={reload}
                                    redirectsPerPage={redirectsPerPage}
                                    handleRedirectsPerPageChange={handleRedirectsPerPageChange}
                                    redirectsViewMode={redirectsViewMode}
                                    setRedirectsViewMode={setRedirectsViewModeLS}
                                    redirectsChartType={redirectsChartType}
                                    setRedirectsChartType={setRedirectsChartTypeLS}
                                    matchesRedirectsHistoryOnly={matchesDownLg}
                                />
                                {   
                                    redirectsNumOfResults > 0 ?
                                    <Box>
                                        {
                                            redirectsViewMode === redirectsViewModes.history || matchesDownLg ?
                                            <>
                                                {
                                                    redirects.map((redirect, index) => (
                                                        <RedirectHistoryRow 
                                                            redirect={redirect} 
                                                            index={index}
                                                        />
                                                    ))
                                                }
                                            </> :
                                            <Box 
                                                sx={{
                                                    width: '100%', 
                                                    display: 'flex', 
                                                    flexDirection: 'column', 
                                                    alignItems: 'center',
                                                    gap: redirectsChartType === redirectsChartTypes.pie && 2,
                                                }}
                                            >
                                                <RedirectHistoryChart 
                                                    labels={labels}
                                                    series={series}
                                                    redirectsChartType={redirectsChartType}
                                                    redirectsNumOfResults={redirectsNumOfResults}
                                                />
                                            </Box>
                                        }
                                    </Box> :
                                    <NoData text='No redirects'/>
                                }
                                {
                                    redirectsViewMode === redirectsViewModes.chart && availablePeriodOptions.length > 0 &&
                                    <PeriodOptions 
                                        period={chartPeriod} 
                                        setPeriod={setChartPeriod} 
                                        availableOptions={availablePeriodOptions}
                                    />
                                }
                            </Box>
                            {   
                                redirectsViewMode === redirectsViewModes.history && redirectsTotalPages > 1 && 
                                <Box sx={{width: '100%', mt: 3, display: 'flex', justifyContent: 'center'}}>
                                    <Pagination
                                        count={redirectsTotalPages} 
                                        page={redirectsOffset + 1} 
                                        variant="outlined" 
                                        color="primary" 
                                        size={matches ? "medium" : "large"}
                                        onChange={handleOffsetChange}
                                    />
                                </Box>
                            }
                        </>
                    }
                </>
            }
        </>
    );
});

export {
    redirectsViewModes,
    redirectsChartTypes,
}

export default withPather()(RedirectHistory);