import React from 'react';
import { 
    useTheme,
    Box,
    Drawer as MUIDrawer,
    List, 
    IconButton,
    Divider,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Badge
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { 
    useSelector, 
    useDispatch 
} from 'react-redux';
import {
    LightModeRounded,
    DarkModeRounded
} from '@mui/icons-material';
import settingsTabs from './settingsTabs';
import { toggleTheme } from '../../../slices/themeSlice';
import { roleIcon } from '../../../utils';

const SettingsDrawer = ({ open, toggleDrawer, setValue }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();

    const user = useSelector(state => state.user.value);

    const list = (
        <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                <Box sx={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1}}>
                    <Box 
                        className="noselect"
                        sx={{ 
                            fontFamily: 'Emblema One', 
                            color: theme.palette.text.main, 
                            fontSize: 22,
                            cursor: 'pointer'
                        }}
                        onClick={() => navigate('/')}
                    >
                        <h1>Shortl</h1>
                    </Box>
                    <Box>
                        <IconButton onClick={() => dispatch(toggleTheme())} sx={{cursor: 'pointer'}}>
                            { 
                                theme.palette.mode === 'dark' ?
                                <LightModeRounded fontSize='large' /> :
                                <DarkModeRounded fontSize='large' />
                            }
                        </IconButton> 
                    </Box>
                </Box>
                {
                    user?.isLogined &&
                    <>
                        <Divider sx={{mb: 2}}/>
                        <Box sx={{
                            display: 'flex', width: '100%', 
                            height: '100%', justifyContent: 'center', 
                            alignItems: 'center', fontSize: '1.5rem',
                            mb: 2, gap: 1, 
                        }}>
                            <Box sx={{overflow: 'auto'}}>  
                                {user.username}
                            </Box>
                            <Box>
                                { roleIcon() }
                            </Box>
                        </Box>
                    </>
                }
                <Divider sx={{mb: 2}}/>
                {   
                    settingsTabs.map(current => {
                        return (
                            <ListItem key={current.id} disablePadding>
                                <ListItemButton onClick={() => setValue(current.id)}>
                                    <ListItemIcon>
                                        {   
                                            current?.adminOnly ?
                                            <>
                                                <Badge badgeContent={
                                                    React.cloneElement(
                                                        roleIcon('admin'),
                                                        { 
                                                            width: "14", 
                                                            height: "14",
                                                        }
                                                    )
                                                }>
                                                    {
                                                        React.cloneElement(
                                                            current.icon,
                                                            { 
                                                                style: {
                                                                    fontSize: 28
                                                                } 
                                                            }
                                                        )
                                                    }
                                                </Badge>
                                            </> :
                                            React.cloneElement(
                                                current.icon,
                                                {fontSize: 'large'}
                                            )
                                        }
                                    </ListItemIcon>
                                    <ListItemText primary={current.label} />
                                </ListItemButton>
                            </ListItem>
                        )
                    })
                }
            </List>
        </Box>
    );

    return (
        <Box>
            <MUIDrawer
                anchor='left'
                open={open}
                onClose={toggleDrawer(false)}
            >
                {list}
            </MUIDrawer>
        </Box>
    );
}
export default SettingsDrawer;