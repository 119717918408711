import './App.css';
import { useMemo, useEffect } from 'react';
import { BrowserRouter } from "react-router-dom";
import { 
  createTheme, 
  ThemeProvider, 
  GlobalStyles,
} from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { PatherProvider, Pather } from 'react-pather'
import axios from 'axios';
import themes from './theme';
import { frontRoutes, backBoutes } from './routes';
import Router from './routers/Router';
import { setUser } from './slices/userSlice';
import { setUserLoading } from './slices/userLoadingSlice';
import { AutoScrollToTop } from './components/general';

const pather = new Pather(frontRoutes, backBoutes);

const App = () => {

  const mode = useSelector(state => state.theme.value);
  const jwt = useSelector(state => state.jwt.value);
  const theme = useMemo(() => createTheme(themes[mode]), [mode]);
  const dispatch = useDispatch();

  useEffect(() => {
      // Authrorization header settings
      if(!!jwt){
        axios.defaults.headers.common['Authorization'] = `Bearer ${jwt}`; // <-| TODO: think whether it's good
      }
    
      // At the beginning `userLoading` state value is `true`, this call is for consistency
      dispatch(setUserLoading(true));

      // Current user restoration
      axios.get(pather.back.Auth.currentUser)
      .then(response => {
          console.log(response);
          const data = response.data;
          dispatch(setUser({
              isLogined: true,
              email: data.email,
              username: data.username,
              isConfirmed: data.is_confirmed,
              roles: data.roles,
          }));
      })
      .catch(resp => {
        console.log(resp);
      })
      .finally(() => {
        dispatch(setUserLoading(false));
      });
  }, []);

  return (
    <>
      <GlobalStyles styles={{
        body: { 
          backgroundColor: theme.palette.background.default,
          color: theme.palette.text.main,
        },
      }}/>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3} autoHideDuration={3000} preventDuplicate={true}>
          <PatherProvider value={pather}>
            <BrowserRouter>
              <Router />
              <AutoScrollToTop />
            </BrowserRouter>
          </PatherProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </>
  );
}

export default App;