import { createSlice } from '@reduxjs/toolkit'

export const settingsPageSlice = createSlice({
    name: 'settingsPage',
    initialState: {
      value: 0,
    },
    reducers: {
      setSettingsPage: (state, action) => {
        state.value = action.payload;
      },
      setSettingsPageToForgotten: (state) => {
        state.value = 2;  // `2` is an id for `Forgotten` tab on the `Settings` page. See `settingsTabs.js`.
      },
      resetSettingsPage: (state) => {
        state.value = 0;
      },
    }
})

export const { 
  setSettingsPage, 
  setSettingsPageToForgotten,
  resetSettingsPage, 
} = settingsPageSlice.actions

export default settingsPageSlice.reducer;
