import { 
    useEffect, 
    useState, 
} from 'react';
import { 
    useSelector, 
    useDispatch, 
} from 'react-redux';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import {
    Container,
    Box,
    Grid,
} from '@mui/material';
import { withPather } from 'react-pather';
import Result from './result';
import URLInput from './URLInput';
import Period from './Period';
import Advanced from './advanced';
import StaticDatePicker from './StaticDatePicker';
import ShortenBtn from './ShortenBtn';
import Slug from './slug';
import { reservedSlugMode as reservedAutoCodeSlugMode } from './slug/AutoCode';
import { reservedSlugMode as reservedSmartCodeSlugMode } from './slug/SmartCode';
import { reservedSlugMode as reservedCustomCodeSlugMode } from './slug/CustomCode';
import Loader from '../Loader';
import { __DEV__, UUT, UUTLC } from '../../config';
import { setToken } from '../../slices/tokenSlice';
import { useEffectExceptOnMount } from '../../hooks';
import { isAdmin } from '../../utils';

const DEFAULT_PERIOD_DAYS = 183;

const Shorten = ({ pather }) => {

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    // Main basic settings
    const [url, setUrl] = useState('');
    const [days, setDays] = useState(DEFAULT_PERIOD_DAYS);
    const [code, setCode] = useState('');  // <-| Saves code that is already assigned to a Conversion
    const [favourite, setFavourite] = useState(false);
    const [slugMode, setSlugMode] = useState(reservedAutoCodeSlugMode);
    const user = useSelector(state => state.user.value);

    // Booleans
    const [showResult, setShowResult] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isAdvancedExpanded, setAdvancedExpanded] = useState(false);
    const [isSlugSettingsExpanded, setSlugSettingsExpanded] = useState(false);

    const [isPathOccupied, setIsPathOccupied] = useState(false);
    const [loadingCustomCodeCheck, setLoadingCustomCodeCheck] = useState(false);
    const [isCustomCodeInvalid, setIsCustomCodeInvalid] = useState(false);
    //
    
    // Storing Advanced settings
    const [advanced, setAdvanced] = useState({
        maxRedirects: null,
        revealable: true,
        customCode: '',
        isDead: false,
        passcode: null,
        isSmartCode: false,
    });

    const setAdvancedSafe = updStateObj => {
        setAdvanced(prevState => ({
            ...prevState,
            ...updStateObj,
        }));    
    }
    //

    // Additional Settings (those that are related to advanced but aren't used in the request)
    const [additional, setAdditional] = useState({
        isCustomCodeAvailable: null,  // null - no info, true - available, false - not available    
        notifying: false,
        locking: false,
    });

    const setAdditionalSafe = updStateObj => {
        setAdditional(prevState => ({
            ...prevState,
            ...updStateObj,
        }));
    }
    //

    useEffect(() => {
        // Reset prev values
        if (isCustomCodeInvalid) setIsCustomCodeInvalid(false);
        if (additional.isCustomCodeAvailable !== null) setAdditionalSafe({isCustomCodeAvailable: null});
        if (isPathOccupied) setIsPathOccupied(false);

        // When custom code is ''
        if (!advanced.customCode) {
            setIsCustomCodeInvalid(false);
            return;
        }

        //  Check code validity
        if (!isCodeValid(advanced.customCode)) {
            setIsCustomCodeInvalid(true);
            return;
        }

        // Check code availability
        checkCodeAvalability();
    }, [advanced.customCode])

    useEffect(() => {
        if (__DEV__ && url === 'test') setUrl('https://react.dev/');
    }, [url])

    useEffectExceptOnMount(() => {
        setAdvancedSafe({isSmartCode: slugMode === reservedSmartCodeSlugMode});
        if(slugMode !== reservedCustomCodeSlugMode) setAdvancedSafe({customCode: ''});
    }, [slugMode]);

    const checkCodeAvalability = () => {
        if(advanced.customCode === code) {
            setAdditionalSafe({isCustomCodeAvailable: true});
            return;
        }
        setLoadingCustomCodeCheck(true);
        axios.get(
            pather.reverse(pather.back.Conversion.redirectData, { code: advanced.customCode.trim() })
        ).then(res => {
            setAdditionalSafe({isCustomCodeAvailable: !!res.data.isCodeAvailable});
        })
        .catch(err => {
            console.log(err);
        })
        .finally(() => {
            setLoadingCustomCodeCheck(false);
        });
    }

    const pathExists = code => {
        const front = pather.front;
        for (const prop in front) {
            if (Object.prototype.hasOwnProperty.call(front, prop)) {
                if (prop === code) {
                    setIsPathOccupied(true);
                    return true;
                }
                if (typeof front[prop] === "string" && (front[prop].match(/\//g) || []).length === 1) {  // If the amount of forward slashes is equal to 1
                    const clearPath = front[prop].replace(/\//g, "");
                    if (clearPath === code) {
                        setIsPathOccupied(true);
                        return true;
                    }
                }
            }
        }
        setIsPathOccupied(false);
        return false;
    }

    // Custom Code (CC) Validation
    const isCodeValid = (code, notify = false) => {
        if(!code) {
            notify && enqueueSnackbar('Custom code is not provided!', { variant: 'error' });
            return false;
        }
        else if(code.length < 5) {
            notify && enqueueSnackbar('Custom code length must be at least 5 characters!', { variant: 'error' });
            return false;
        }
        else if (code.length >= 100){
            notify && enqueueSnackbar('Custom code length must be less than 100!', { variant: 'error' });
            return false;
        }
        else if(!/^[A-Za-z0-9-_]*$/.test(code)){
            notify && enqueueSnackbar('Wrong custom code input!', { variant: 'error' })
            return false;
        }
        else if(pathExists(code)){
            notify && enqueueSnackbar('Current custom code is reserved!', { variant: 'error' })
            return false;
        }
        return true;
    }

    // Other fields validation
    const isFieldsValid = () => {
        if (!!!url.trim()){
            enqueueSnackbar('URL field is not filled!', { variant: 'error' });
            return false;
        }
        else if (!pather.isURLValid(url.trim(), true)){
            enqueueSnackbar('Entered url is incorrect!', { variant: 'error' });
            return false;
        }
        else if (days !== '' && days <= 0) {
            enqueueSnackbar('Duration must be bigger that 0 days!', { variant: 'error' });
            return false;
        }
        else if (!user?.isLogined && days > 1095) {  // 3 years
            enqueueSnackbar('To shorten for longer periods you have to be authenticated!', { variant: 'error' });
            return false;
        }
        else if ((advanced.maxRedirects != null && advanced.maxRedirects <= 0) || (advanced.maxRedirects != null && isNaN(parseInt(advanced.maxRedirects)))) {
            enqueueSnackbar('Max redirects number must be bigger that 0!', { variant: 'error' });
            return false;
        }
        else if(slugMode === reservedCustomCodeSlugMode) {
            if (!isCodeValid(advanced.customCode, true)) return false;
            else if ((!!!days || days > 100) && !isAdmin()) {
                enqueueSnackbar('Link with custom code can be active maximum for 100 days!', { variant: 'error' });
                return false;
            }
        }
        else if (advanced.passcode !== null){
            if (advanced.passcode === '') {
                enqueueSnackbar('Passcode is not set!', { variant: 'error' });
                return false;
            }
            else if (advanced.passcode.length < 5){
                enqueueSnackbar('Passcode must contain at least 5 characters!', { variant: 'error' });
                return false;
            }
        }
        return true;
    }
    //

    const shorten = () => {
        if(!isFieldsValid()) return;
        setIsLoading(true);
        !!code ? edit() : create();
    }   

    const create = () => {
        axios.post(
            pather.back.Conversion.shorten, 
            { 
                source: url, 
                days: days, 
                username: user.username,
                ...advanced
            }
        )
        .then(response => {
            const data = response.data;
            setCode(data.code);
            setShowResult(true);
            const uut = localStorage.getItem(UUTLC);
            if(!uut){
                dispatch(setToken(data.token));
                axios.defaults.headers.common[UUT] = data.token;
            }
        })
        .catch(error => {
            console.log(error);
            const status = error.response.data.status;
            if(!!status){
                enqueueSnackbar(status, { variant: 'error' });
            }
        })
        .finally(() => {
            setIsLoading(false);
        });
    }

    const edit = () => {
        axios.put(
            pather.reverse(pather.back.Conversion.code, { code }),
            { 
                source: url, 
                days: days, 
                slugMode,
                ...advanced
            }
        )
        .then(response => {
            const data = response.data;
            if (!!data.new_code) setCode(data.new_code);
            setShowResult(true);
        })
        .catch(error => {
            console.log(error);
            const status = error.response.data.status;
            if(!!status) enqueueSnackbar(status, { variant: 'error' });
        })
        .finally(() => {
            setIsLoading(false);
        });
    }

    const handleRegenerate = () => {
        setIsLoading(true);
        axios.post(
            pather.reverse(pather.back.Conversion.regenerate, { code }), 
            { 
                source: url, 
                days: days 
            },
        )
        .then(response => {
            setCode(response.data.code);
            setShowResult(true);
            resetCustomCode();
            setAdvancedSafe({ isSmartCode: false });
            setSlugMode(reservedAutoCodeSlugMode);
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            setIsLoading(false);
        });
    }

    const handleClear = () => {
        setCode(''); 
        setShowResult(false);
        setDays(DEFAULT_PERIOD_DAYS);
        setUrl('');
        setAdvancedSafe({
            maxRedirects: null,
            revealable: true,
            customCode: '',
            isDead: false,
            passcode: null,
            isSmartCode: false,
        });
        setAdditionalSafe({
            locking: false,
        })
        setSlugSettingsExpanded(false);
        setSlugMode(reservedAutoCodeSlugMode);
    }

    const resetCustomCode = () => {
        setAdvancedSafe({
            customCode: '',
        })
    }

    return (
        <Container sx={{height: '100%'}}>
            {
                isLoading ? 
                <Loader/> :
                <>
                    {
                        showResult ? 
                        <Result 
                            shortenedUrl={`${window.location.origin}${pather.reverse(pather.front.main, { code })}`}
                            handleRegenerate={handleRegenerate}
                            handleClear={handleClear}
                            setShowResult={setShowResult}
                            code={code}
                            favourite={favourite}
                            setFavourite={setFavourite}
                        /> :
                        <Box>
                            <ShortenMain 
                                url={url}
                                setUrl={setUrl}
                                days={days}
                                setDays={setDays}
                            />
                            <ShortenExtended
                                days={days}
                                advanced={advanced}
                                setAdvancedSafe={setAdvancedSafe}
                                isSlugSettingsExpanded={isSlugSettingsExpanded}
                                setSlugSettingsExpanded={setSlugSettingsExpanded}
                                loadingCustomCodeCheck={loadingCustomCodeCheck}
                                isCustomCodeInvalid={isCustomCodeInvalid}
                                additional={additional}
                                setAdditionalSafe={setAdditionalSafe}
                                isPathOccupied={isPathOccupied}
                                slugMode={slugMode}
                                setSlugMode={setSlugMode}
                                isAdvancedExpanded={isAdvancedExpanded}
                                setAdvancedExpanded={setAdvancedExpanded}
                            />
                            <ShortenBtn shorten={shorten}/>
                        </Box>
                    }
                </>
            }
        </Container>
    );
};

const ShortenMain = ({ url, setUrl, days, setDays }) => {
    return (
        <Box sx={{mt: 10}}>
            <Grid container spacing={4}>
                <Grid item xs={12} md={12}>
                    <URLInput url={url} setUrl={setUrl} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Period 
                        days={days} 
                        handleDaysChange={e => setDays(e.target.value)} 
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <StaticDatePicker days={days} setDays={setDays} />
                </Grid>
            </Grid>
        </Box>
    );
}

const ShortenExtended = props => {

    const {
        days,
        advanced,
        setAdvancedSafe,
        isSlugSettingsExpanded,
        setSlugSettingsExpanded,
        loadingCustomCodeCheck,
        isCustomCodeInvalid,
        additional,
        setAdditionalSafe,
        isPathOccupied,
        slugMode,
        setSlugMode,
        isAdvancedExpanded,
        setAdvancedExpanded,
    } = props;

    const user = useSelector(state => state.user.value);

    return (
        <Box sx={{mt: '2vw'}}>
            {   
                user?.isLogined &&
                <Slug
                    advanced={advanced}
                    setAdvancedSafe={setAdvancedSafe}
                    isSlugSettingsExpanded={isSlugSettingsExpanded} 
                    setSlugSettingsExpanded={setSlugSettingsExpanded} 
                    loadingCustomCodeCheck={loadingCustomCodeCheck}
                    isCustomCodeInvalid={isCustomCodeInvalid}
                    additional={additional}
                    isPathOccupied={isPathOccupied}
                    slugMode={slugMode}
                    setSlugMode={setSlugMode}
                />
            }
            <Advanced
                days={days}
                advanced={advanced}
                setAdvancedSafe={setAdvancedSafe}
                isAdvancedExpanded={isAdvancedExpanded}
                setAdvancedExpanded={setAdvancedExpanded}
                additional={additional}
                setAdditionalSafe={setAdditionalSafe}
            />
        </Box>
    );
}

export default withPather()(Shorten);