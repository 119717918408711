// Route names must be unique and start with /
const routes = {
    main: '/:code',  
    shorten: '/shorten',  
    login: '/login',  
    signup: '/signup',  
    dashboard: '/dashboard',  
    register: '/register',  
    view: '/view/:code',  
    settings: '/settings',
    confirmAccount: '/confirm/:uut',
    resetPassword: '/reset-password',
    reset: '/reset/:token',
}

export default routes;