import { useState } from "react";
import { 
    Container, 
    Box, 
    Button, 
    TextField,
    Typography, 
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { withPather } from "react-pather";
import axios from "axios";
import Loader from "../Loader";

const ResetPassword = ({ pather }) => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState();
    const [login, setLogin] = useState('');
    const [isSent, setIsSent] = useState(false);

    const getResetLink = () => {
        setLoading(true);
        axios.post(pather.back.Reset.link, { login })
        .then(response => {
            setIsSent(true);
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    return (
        <Container sx={{height: '100%'}}>
            {
                loading ? 
                <Loader/> :
                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 3, mt: {xs: 5, sm: 15}, mb: {xs: 5, sm: 20}}}>
                    {
                        isSent ?
                        <>
                            <Typography
                                component="div"
                                sx={{
                                    fontSize: '32px'
                                }}
                                gutterBottom
                            >
                                Reset email was successfully sent
                            </Typography>
                            <Button 
                                variant="contained" 
                                sx={{pt: 1, pb: 1, pl: 3, pr: 3}}
                                onClick={() => navigate(pather.front.login)}
                            >
                                Return to Login
                            </Button>
                        </> :   
                        <>
                            <Typography
                                component="div"
                                sx={{
                                    fontSize: '32px'
                                }}
                                gutterBottom
                            >
                                Reset Password
                            </Typography>
                            <Box sx={{width: '100%', display: 'flex', flexDirection: 'column', gap: 2, width: {xs: '100%', sm: '70%', md: '30vw'}}}>
                                <Box>
                                    <Typography 
                                        component="div"
                                        sx={{
                                            fontSize: '16px'
                                        }}
                                        gutterBottom
                                    >
                                        Enter the email address or username you use to login.
                                    </Typography>
                                    <TextField 
                                        label="Email or username" 
                                        sx={{minWidth: '100%'}}
                                        value={login}
                                        onChange={e => setLogin(e.target.value.trim())} 
                                    /> 
                                </Box>
                                <Button 
                                    variant="contained" 
                                    sx={{width: '100%', pt: 1, pb: 1}}
                                    onClick={getResetLink}
                                >
                                    Get Password Reset Link
                                </Button>
                            </Box>
                        </>
                    }
                </Box>
            }
        </Container>
    );
}

export default withPather()(ResetPassword);