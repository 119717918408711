import { 
    useState,    
    useEffect, 
} from 'react';
import { 
    useTheme,
    useMediaQuery,
    Box, 
    Typography,
    ButtonGroup,
    Button,
    IconButton,
} from '@mui/material';
import { 
    BarChart, 
    LineChart, 
} from '@mui/x-charts';
import {
    BarChartRounded,
    TimelineRounded, 
    ArrowForwardIosRounded,
    ArrowBackIosRounded,
} from '@mui/icons-material';
import { withPather } from 'react-pather';
import axios from 'axios';
import { motion } from "framer-motion";
import 
    SeparateChart, 
    { reservedChartType as reservedSeparateChartType }  
from './SeparateChart';
import 
    CombinedChart, 
    { reservedChartType as reservedCombinedChartType }
from './CombinedChart';
import { 
    PeriodOptions, 
    periodOptions, 
} from '../common';
import { reservedSlugMode as reservedAutoCodeSlugMode } from '../../../../shorten/slug/AutoCode';
import { reservedSlugMode as reservedSmartCodeSlugMode } from '../../../../shorten/slug/SmartCode';
import { reservedSlugMode as reservedCustomCodeSlugMode } from '../../../../shorten/slug/CustomCode';
import Loader from '../../../../Loader';
import { useEffectExceptOnMount } from '../../../../../hooks';

const conversionsChartTypes = {
    timeline: 'timeline',
    bar: 'bar',
}

const Statistics = ({ pather }) => {

    const theme = useTheme();

    const matchesDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const matchesUpSm = useMediaQuery(theme.breakpoints.up('sm'));

    const [loading, setLoading] = useState(false);
    const [showSideBlock, setShowSideBlock] = useState(true);

    const [separateChartTypes, setSeparateChartTypes] = useState([]);
    const [chartType, setChartType] = useState(reservedCombinedChartType);
    const [period, setPeriod] = useState(periodOptions[0]);

    const [isEmpty, setIsEmpty] = useState(false);
    const [info, setInfo] = useState({});
    const [chartLabels, setChartLabels] = useState([]);
    const [series, setSeries] = useState([]);

    const [conversionsChartType, setConversionsChartType] = useState(conversionsChartTypes.bar);

    useEffect(() => {
        chartType === reservedSeparateChartType ? 
            setSeparateChartTypes([
                reservedAutoCodeSlugMode,
                reservedCustomCodeSlugMode,
                reservedSmartCodeSlugMode,
            ]) :
            setSeparateChartTypes([]);
    }, [chartType])

    useEffect(() => {
        setIsEmpty(!!!Object.values(info).reduce((accumulator, currentValue) => accumulator + currentValue, 0));
    }, [info]);

    useEffect(() => {
        setInfo({});
        series.forEach(serie => {
            setInfoSafe({
                [serie.label]: serie.data.reduce((accumulator, currentValue) => accumulator + currentValue, 0), 
            })
        });
    }, [series]);

    const setInfoSafe = updStateObj => {
        setInfo(prevState => ({
            ...prevState,
            ...updStateObj,
        }));    
    }

    const fetchStatistics = (isMount = false) => {
        setLoading(true);        
        axios.get(
            pather.back.Settings.statistics, 
            { 
                params: { 
                    period: period.value,
                    chartType,
                    separateChartTypes: JSON.stringify(separateChartTypes),
                    isMount: isMount ? 1 : 0
                }
            }
        )
        .then(response => {
            const data = response.data;
            console.log(data);
            setChartLabels(data?.labels);
            setSeries(data?.series);
            if (isMount && period.value !== data?.taken_period) {
                const newPeriod = periodOptions.find(option => option.value === data?.taken_period);
                setPeriod(newPeriod);
            }
        })
        .catch(error => {
            console.log(error);
            const status = error?.response?.data?.status;
            status && console.log(status);
        }).finally(() => {
            setLoading(false);
        });
    }

    useEffect(() => {
        fetchStatistics(true);
    }, [])

    useEffectExceptOnMount(() => {
        fetchStatistics();
    }, [chartType, separateChartTypes, period]);

    const setSeparateChartTypesSafe = (checked, value) => {
        if(checked && !separateChartTypes.includes(value)){
            setSeparateChartTypes([
                ...separateChartTypes,
                value,
            ]);
        } else {
            setSeparateChartTypes([
                ...separateChartTypes.filter(item =>item !== value)
            ]);
        }
    }

    return (
        <>
            {
                loading ? 
                <Loader/> :
                <Box sx={{display: 'flex'}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Box>
                            <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                                <Box sx={{display: 'flex', gap: 5}}>
                                    {
                                        Object.keys(info).map((label, index) => {
                                            return (
                                                <Typography key={index} variant="h6" component="div">
                                                    {label}: {info[label]}
                                                </Typography>
                                            );
                                        })
                                    }
                                </Box>
                            </Box>
                            <Box sx={{
                                width: '100%', display: 'flex', flexDirection: 'column',
                                justifyContent: 'center', alignItems: 'center'
                            }}>
                                <ConversionChart 
                                    width={matchesDownMd ? (matchesUpSm ? 500 : 400) : (showSideBlock ? 700 : 900)}
                                    height={matchesDownMd ? (matchesUpSm ? 350 : 300) : (showSideBlock ? 500: 550)}
                                    series={series}
                                    labels={chartLabels}
                                    conversionsChartType={conversionsChartType}
                                    isEmpty={isEmpty}
                                />
                                <PeriodOptions 
                                    period={period} 
                                    setPeriod={setPeriod}
                                    size={matchesDownMd ? 'small' : 'medium'}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{width: '100%'}}>
                        <motion.div
                            animate={{ x: showSideBlock ? 0 : "20%" }}  // Slide in when open, slide out when closed
                            transition={{ type: "spring", stiffness: 300, damping: 30 }}  // Smooth transition effect
                            style={{ height: '100%' }}
                        >
                            {
                                !matchesDownMd && 
                                <ExpandableSideBlock 
                                    conversionsChartType={conversionsChartType}
                                    setConversionsChartType={setConversionsChartType}
                                    chartType={chartType}
                                    setChartType={setChartType}
                                    separateChartTypes={separateChartTypes}
                                    setSeparateChartTypesSafe={setSeparateChartTypesSafe}
                                    showSideBlock={showSideBlock}
                                    setShowSideBlock={setShowSideBlock}
                                />
                            }
                        </motion.div>
                    </Box>
                </Box>
            }
        </>
    );
}

const ExpandableSideBlock = props => {
    const { 
        conversionsChartType, 
        setConversionsChartType,
        chartType, 
        setChartType,
        separateChartTypes,
        setSeparateChartTypesSafe,
        showSideBlock,
        setShowSideBlock,
    } = props;
    return (
        <>
            {   
                showSideBlock ?
                <Box sx={{height: '100%'}}>  
                    <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
                        <Box>
                            <ButtonGroup 
                                variant="outlined" 
                                aria-label="Chart types button group" 
                                size="small"
                            >
                                <Button
                                    variant={conversionsChartType === conversionsChartTypes.bar ? 'contained' : 'outlined'}
                                    onClick={() => setConversionsChartType(conversionsChartTypes.bar)}
                                >
                                    <BarChartRounded/>
                                </Button>
                                <Button
                                    variant={conversionsChartType === conversionsChartTypes.timeline ? 'contained' : 'outlined'}
                                    onClick={() => setConversionsChartType(conversionsChartTypes.timeline)}
                                >
                                    <TimelineRounded/>
                                </Button>
                            </ButtonGroup>
                        </Box>
                        <Box sx={{marginLeft: 'auto'}}>
                            <IconButton aria-label="hide-side-block" onClick={() => setShowSideBlock(false)}>
                                <ArrowForwardIosRounded />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={{height: '100%', display: 'flex', alignContent: 'center', justifyContent: 'center'}}>
                        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: 2, pb: '10vh'}}> 
                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <Typography variant="h6" sx={{fontWeight: 'bold'}} component="div">
                                    Chart Type
                                </Typography>
                            </Box>
                            <SeparateChart
                                currentValue={chartType}
                                setValue={setChartType}
                                separateChartTypes={separateChartTypes}
                                setSeparateChartTypesSafe={setSeparateChartTypesSafe}
                                highlightSelectedTitle={true}
                            />
                            <CombinedChart
                                currentValue={chartType}
                                setValue={setChartType}
                                highlightSelectedTitle={true}
                            />
                        </Box>
                    </Box>
                </Box> : 
                <Box>
                    <IconButton aria-label="show-side-block" onClick={() => setShowSideBlock(true)}>
                        <ArrowBackIosRounded />
                    </IconButton>
                </Box>
            }
        </>
    );
}

const ConversionChart = ({ width, height, series, labels, isEmpty, conversionsChartType }) => {

    const bar = (
        <BarChart
            xAxis={[{ data: labels, scaleType: 'band' }]}
            series={series}
            width={width}
            height={height}
        />
    );

    const timeline = (
        <LineChart
            xAxis={[{ scaleType: 'point', data: labels }]}
            series={series}
            width={width}
            height={height}
        />
    );


    const charts = {
        bar,
        timeline,
    }

    return(
        <Box> 
            {   
                series.length > 0 && labels.length > 0 && !isEmpty ?
                charts[conversionsChartType] :
                <Box sx={{
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center',
                    height: height,
                    width: width,
                }}>
                    <Typography variant="h6" component="div">
                        No data
                    </Typography>
                </Box>
            }
        </Box>
    );
}

export {
    periodOptions,
}

export default withPather()(Statistics);