import { Box } from '@mui/material';
import { 
    BarChart,
    LineChart, 
    PieChart, 
} from '@mui/x-charts';
import NoData from './NoData';


const CHART_WIDTH = 1000;
const CHART_HEIGHT= 700;

const getArcLabel = params => `${params.value}%`;

const RedirectHistoryChart = props => {
    
    const {
        redirectsChartType,
        labels,
        series,
        redirectsNumOfResults,
    } = props;

    const timeline = (
        <LineChart
            xAxis={[{ scaleType: 'point', data: labels }]}
            series={series}
            width={CHART_WIDTH}
            height={CHART_HEIGHT}
        />
    );

    const bar = (
        <BarChart
            xAxis={[{ scaleType: 'band', data: labels }]}
            series={series}
            width={CHART_WIDTH}
            height={CHART_HEIGHT}
        />
    );

    const pie = (
        <PieChart
            width={CHART_WIDTH}
            height={400}
            series={series.map(it => (
                {
                    ...it,
                    innerRadius: 0,
                    arcLabel: getArcLabel,
                }
            ))}
            tooltip={null}
        />
    );

    const charts = {
        bar,
        timeline,
        pie,
    }

    return (
        <Box> 
            { 
                series.length > 0 && redirectsNumOfResults > 0 ?
                <> { charts[redirectsChartType] } </> :
                <NoData minHeight='50vh' />
            }
        </Box>
    );
}

export default RedirectHistoryChart;