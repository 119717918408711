import { Icon } from '@iconify/react';
import store from "../store";


const root = 'root';
const admin = 'admin';


const roles = {
    [root]: {
        icon: (
            <Icon 
                icon="mdi:crown" 
                color="#201E43" 
                width="32" 
                height="32"
            />
        ),
    },
    [admin]: {
        icon: (
            <Icon 
                icon="mdi:crown" 
                color="#f0a500" 
                width="32" 
                height="32"
            />
        ),
    },
}


function roleIcon(role){
    if (role) return roles[role]?.icon;
    if (isRoot()) return roles[root].icon;
    else if (isAdmin()) return roles[admin].icon;
    else return null;
}

function getUser(){
    const state = store.getState();
    const user = state.user.value;
    return user;
}

function isAdmin(){
    const user = getUser();
    return (user?.roles ?? []).includes(admin) || isRoot();
}

function isRoot(){
    const user = getUser();
    return (user?.roles ?? []).includes(root);
}


export {
    roleIcon,
    isAdmin,
    isRoot,
}