import { 
    useTheme,
    useMediaQuery,
    Box,
    FormControl,
    TextField,
    RadioGroup,
    FormControlLabel,
    Radio,
    Typography
} from '@mui/material';
import { useSelector } from 'react-redux';
import LoginMessage from './advanced/LoginMessage';


const Period = ({ days, handleDaysChange }) => {
    const theme = useTheme();
    const user = useSelector(state => state.user.value);
    return (
        <Box className="container-card">
            <Box className="square" sx={{ 
                boxShadow: theme.palette.squareBoxShadow,
                display:'flex', justifyContent: 'center', alignItems: 'center', 
            }}>
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 2}}>
                    <FormControl sx={{width: {xs: '75%', sm: '70%'}, height: '75%', display: 'flex', flexDirection: 'column', gap: 2}}>
                        <Typography component="div" fontSize='22px'>
                            Active period
                        </Typography>
                        <TextField
                            id="outlined-number"
                            label="Amount of days"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={days}
                            onChange={handleDaysChange}
                            variant="standard" 
                        />
                        <PeriodOptions 
                            days={days} 
                            handleDaysChange={handleDaysChange} 
                        />
                    </FormControl>
                    {
                        !user?.isLogined && 
                        <Box sx={{width: '90%', display: 'flex', justifyContent: 'center'}}>
                            <LoginMessage message={<span>To shorten for <i>indefinite</i> period please</span>} />
                        </Box>
                    }
                </Box>
            </Box>
        </Box>
    );
}

const periodOptions = [
    {value: '', label: "Indefinite", shortlLabel: "Ind", isLogined: true},
    {value: 1, label: "1 day", shortlLabel: "1d"},
    {value: 3, label: "3 days", shortlLabel: "3d"},
    {value: 7, label: "1 week", shortlLabel: "1w"},
    {value: 31, label: "1 month", shortlLabel: "1m"},
    {value: 91, label: "3 months", shortlLabel: "3m"},
    {value: 183, label: "6 months", shortlLabel: "6m"},
    {value: 365, label: "1 year", shortlLabel: "1y"},
    {value: 1095, label: "3 years", shortlLabel: "3y"},
    {value: 1825, label: "5 years", shortlLabel: "5y", isLogined: true},
]

const PeriodOptions = ({ days, handleDaysChange }) => {

    const theme = useTheme();
    const matchesSm = useMediaQuery(theme.breakpoints.down(365));

    const user = useSelector(state => state.user.value);

    const getChunkPeriodOptions = ({ start = 0, end = undefined }) => {
        return (
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
                {
                    periodOptions.slice(start, end).filter(option => (
                        !option?.isLogined || (user?.isLogined && option?.isLogined) 
                    )).map(current => {
                        return (
                            <FormControlLabel 
                                control={<Radio />} 
                                key={current.value}
                                value={current.value} 
                                label={matchesSm ? current.shortlLabel : current.label} 
                            />
                        );
                    })
                }
            </Box>
        );
    }

    const getAllOptions = () => {
        return (
            <>
                { getChunkPeriodOptions({ end: 5 }) }
                { getChunkPeriodOptions({ start: 5 }) }
            </>
        );
    }

    return (
        <RadioGroup
            value={days}
            onChange={handleDaysChange}
        >   
            <Box sx={{display: 'flex', justifyContent: 'space-around'}}>
                { getAllOptions() }
            </Box>
        </RadioGroup>
    );
}

export {
    periodOptions,
}

export default Period;