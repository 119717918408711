import { 
    useTheme,
    useMediaQuery,
    Box, 
    Chip, 
    Tooltip,
} from '@mui/material';
import { HelpRounded } from '@mui/icons-material';

const RedirectHistoryRow = ({ redirect, index }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Box 
            key={redirect.id} 
            sx={{ 
                display: 'flex', flexDirection: 'column', 
                gap: 1, fontSize: '1rem', borderBottom: '1px solid grey', 
                pb: 2, pt: index === 0 ? 0 : 2, cursor: 'pointer'
            }}
            className="data-row" 
        >
            <Box sx={{display: 'flex', flexDirection: matches ? 'column' : 'row', gap: matches && 1}}>
                <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
                    <Box sx={{fontSize: '1.7rem'}}>
                        {
                            redirect.ip_addr ? redirect.ip_addr : 'ANONIM'
                        }
                    </Box>
                    <Box>
                        {   
                            redirect?.is_successful ?
                            <Chip color="success" size='small' label="Successful" sx={{color: 'white'}} /> :
                            <Chip color="error" size='small' label="Failed" />
                        }
                    </Box>
                </Box>
                <Box sx={{marginLeft: !matches && 'auto', cursor: 'pointer'}}>
                    <Tooltip title='Redirect date' placement='bottom'>
                        {
                            redirect.date
                        }
                    </Tooltip>
                </Box>
            </Box>
            {
                redirect?.status_message &&
                <Box sx={{display: 'flex', gap: .5, alignItems: 'flex-end'}}>
                    <Box sx={{fontWeight: 'bold'}}>
                        {redirect.status_message}
                    </Box>
                    {
                        redirect?.status_explanation &&
                        <Tooltip 
                            title={redirect.status_explanation} 
                            placement="right" 
                            sx={{cursor: 'pointer'}} 
                            arrow
                        >
                            <HelpRounded fontSize='small'/>
                        </Tooltip>
                    }
                </Box>
            }
            {   
                redirect?.user_agent &&
                <Box>
                    {redirect.user_agent}
                </Box>
            }
        </Box>
    );
} 

export default RedirectHistoryRow;