import * as Tabs from './tabs';
import {
    SettingsRounded,
    SignalCellularAltRounded,
    ContactMailRounded,
    QueryStatsRounded,
    TrendingUp,
    DeleteRounded,
} from '@mui/icons-material';

const settingsTabs = [
    {   
        id: 0,
        label: 'General',
        icon: <SettingsRounded/>,
        component: <Tabs.General />,
    },
    {   
        id: 1,
        label: 'Statistics',
        icon: <SignalCellularAltRounded/>,
        component: <Tabs.Statistics />,
    },
    {   
        id: 2,
        label: 'Forgotten',
        disableTitleIntegration: true, // Title will not be added (integrated) to the page automatically
        icon: <DeleteRounded/>,
        component: <Tabs.Forgotten />,
    },
    {   
        id: 21, 
        label: 'Metrics',
        icon: <QueryStatsRounded/>,
        component: <Tabs.Metrics />,
        adminOnly: true,
    },
    {   
        id: 22, 
        label: 'Trends',
        icon: <TrendingUp/>,
        component: <Tabs.Trends />,
        adminOnly: true,
    },
    {   
        id: 3, 
        label: 'Contact us',
        icon: <ContactMailRounded/>,
        component: <Tabs.Contact />,
    },
]

export default settingsTabs;