import {
    Box,
    Button,
} from '@mui/material';
import { useNavigate } from "react-router-dom";
import { withPather } from 'react-pather';
import { motion } from "framer-motion";

const StartNow = ({ pather }) => {
    const navigate = useNavigate();
    return (
        <Box sx={{width: '100%'}}>
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: 2, minHeight: '60vh'}}>
                <Box>
                    <h1>Start using Shortl for free today &#9889;</h1>
                </Box>
                <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                    <motion.div
                        whileHover={{
                            scale: 1.02,        // Scale up when hovered
                            transition: { type: "spring", stiffness: 300, damping: 20 },  // Smooth transition
                        }}
                        whileTap={{
                            scale: 0.95,       // Scale down when clicked (tap)
                            transition: { type: "spring", stiffness: 300, damping: 20 },  // Smooth transition
                        }}
                    >
                        <Button 
                            variant="contained"
                            onClick={() => navigate(pather.front.shorten)}
                            sx={{
                                p: 2, 
                                borderRadius: '30px',
                                width: {md: '25vw',  sm: '80%', xs: '100%'}, 
                                fontWeight: 'bold',
                                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;'
                            }}
                        >
                            Start Now
                        </Button>
                    </motion.div>
                </Box>
            </Box>
        </Box>
    );
}

export default withPather()(StartNow);