import { 
    Box,
    Typography,
} from '@mui/material';

const NoData = ({ text = 'No data', minHeight = '15vh' }) => {
    return (
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight}}>
            <Typography component="div" sx={{fontSize: '18px'}}>
                {text}
            </Typography>
        </Box>
    );
}

export default NoData;