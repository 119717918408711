import { Box } from "@mui/material";
import Link from '@mui/material/Link';
import { useNavigate } from "react-router-dom";
import { withPather } from "react-pather";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

const AuthLink = ({ route, text }) => {
    const navigate = useNavigate();
    return (
        <Link href="#" onClick={() => navigate(route)} sx={{color: '#942c2c', textDecorationColor: '#942c2c' }}>{text}</Link>
    );
}

const LoginMessage = ({ pather, message = 'To unlock more advanced features please' }) => {
    return (
        <Box sx={{
            width: '100%', display: 'flex', alignItems: 'center', fontSize: '1.1rem', p: 2,
            backgroundColor: '#E84545', color: 'white', borderRadius: '15px',
        }}>
            <Box>
                {message}
                &nbsp;<AuthLink text='sign up' route={pather.front.signup}/> or <AuthLink text='login' route={pather.front.login}/>
            </Box>
            <Box sx={{marginLeft: 'auto'}}>
                <PriorityHighIcon/>
            </Box>
        </Box>
    );
}

export default withPather()(LoginMessage);