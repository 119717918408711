// Reset Password 
const Reset= {  
    path: '/reset',
    isSection: true,
    sub: {
        link: '/link',
        reset: '/:token',
        verify: '/:token/verify',
    },
}

export default Reset;