
const Conversion = {
    path: '/conversion',
    isSection: true,
    sub: {
        code: {
            path: '/:code',
            sub: {
                regenerate: '/regenerate',
                deactivateConv: '/deactivate',  // FIXME: bug in pather (wrong url is picked when this url is named `deactivate`)
                delete: '/delete',  
                redirects: '/redirects',  
                checkRedirectPasscode: '/check-passcode',
                forget: '/forget',
                redirectData: '/redirect-data',
                favourite: '/favourite',
                redirectsChart: '/redirects/chart'
            }
        },
        shorten: '/shorten',
    }
}

export default Conversion;