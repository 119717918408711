import {
    ButtonGroup,
    Button,
} from '@mui/material';

const FreshnessSwitcher = ({ freshness, setFreshness }) => {
    return (
        <ButtonGroup
            disableElevation
            sx={{width: '100%'}}
        >
            <Button 
                sx={{
                    borderTopLeftRadius: '20px', borderEndStartRadius: '20px', width: {xs: '100%', md: '100%'}, p: 1,
                    fontWeight: freshness === 'active' && 'bold',
                }} 
                onClick={() => {
                    setFreshness('active');
                }}
                variant={freshness === 'active' ? 'contained' : 'outlined'}
            >
                Active
            </Button>
            <Button 
                sx={{ 
                    width: {xs: '100%', md: '100%'}, p: 1,
                    fontWeight: freshness === 'all' && 'bold',
                }} 
                onClick={() => {
                    setFreshness('all');
                }}
                variant={freshness === 'all' ? 'contained' : 'outlined'}
            >
                All
            </Button>
            <Button 
                sx={{
                    borderTopRightRadius: '20px', borderEndEndRadius: '20px', width: {xs: '100%', md: '100%'}, p: 1,
                    fontWeight: freshness === 'inactive' && 'bold',
                }} 
                onClick={() => setFreshness('inactive')}
                variant={freshness === 'inactive' ? 'contained' : 'outlined'}
            >
                Inactive
            </Button>
        </ButtonGroup>
    );
}

export default FreshnessSwitcher;