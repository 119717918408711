import { useState } from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { useDispatch } from 'react-redux';
import { toggleTheme } from '../../slices/themeSlice';
import { useTheme } from '@mui/material';
import { 
    useNavigate, 
    useLocation, 
} from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import LoginButton from '../auth/LoginButton';
import { useSelector } from "react-redux";
import AccountCircle from '@mui/icons-material/AccountCircle';
import axios from 'axios';
import { setUser } from '../../slices/userSlice';
import useToken from '../auth/useToken';
import { useSnackbar } from 'notistack';
import HeaderMenu from './HeaderMenu';
import { withPather } from 'react-pather';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import useMediaQuery from '@mui/material/useMediaQuery';
import Drawer from './Drawer';
import AccountConfirmation from './AccountConfirmation';
import { resetDashboardSettings } from '../../slices/dashboardSettingsSlice';
import { resetSettingsPage } from '../../slices/settingsPageSlice';
import { resetForgottenSettings } from '../../slices/forgottenSettingsSlice';
import { roleIcon } from '../../utils';

const Header = ({ pather }) => {

    const dispatch = useDispatch();
    const theme = useTheme();
    const navigate = useNavigate();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const user = useSelector(state => state.user.value);
    const matches = useMediaQuery(theme.breakpoints.down('md'));
    const [confirmationLoading, setConfirmationLoading] = useState(false);

    // Drawer
    const [openDrawer, setOpenDrawer] = useState(false);
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
        setOpenDrawer(open);
    };
    //

    const { removeToken } = useToken();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenuClick = e => {
        if (anchorEl !== e.currentTarget) {
            setAnchorEl(e.currentTarget);
        } 
    }
    const handleClose = () => setAnchorEl(null);
    
    const logout = () => {
        axios.post(pather.back.Auth.logout)
        .then(() => {
            removeToken();
            dispatch(setUser({}));
            dispatch(resetDashboardSettings());
            dispatch(resetForgottenSettings());
            dispatch(resetSettingsPage());
        })
        .catch(({ response: { data } }) => {
            enqueueSnackbar(data.status, { variant: 'error' });
            console.log(data.status);
        })
        .finally(() => {
            handleClose();
        })
    }

    const sendConfirmationLink = () => {
        setConfirmationLoading(true);
        axios.post(pather.back.confirmationLink)
        .then(resp => {
            console.log(resp);
            const data = resp?.data;
            enqueueSnackbar(data?.status, { variant: 'success' });
        })
        .catch(resp => {
            const data = resp?.response?.data;
            enqueueSnackbar(data?.status, { variant: 'error' });
            console.log(data);
        })
        .finally(() => {
            setConfirmationLoading(false);
        })
    }

    const icon = roleIcon();

    return (
        <Container sx={{height: '100%'}}>
            {   
                user.isLogined && !user.isConfirmed &&
                <AccountConfirmation 
                    confirmationLoading={confirmationLoading} 
                    sendConfirmationLink={sendConfirmationLink}
                />
            }
            <Box sx={{ pl: 2, display: 'flex', alignItems: 'center', borderBottom: '0.3px solid #AAB4BE'}}>
                <Box>
                    <Box 
                        className="noselect"
                        sx={{ 
                            fontFamily: 'Emblema One', 
                            color: theme.palette.text.main, 
                            fontSize: 22,
                            cursor: 'pointer'
                        }}
                        onClick={() => navigate(location.pathname === '/' ? pather.front.shorten : '/')}
                    >
                        <h1>Shortl</h1>
                    </Box>
                </Box>
                {   
                    matches ?
                    <>
                        { 
                            !openDrawer &&
                            <Box sx={{ml: 'auto', display: 'flex', alignItems: 'center', columnGap: 1}}>
                                <IconButton onClick={toggleDrawer(true)} sx={{pb: 0}}>
                                    <MenuRoundedIcon fontSize='large'/>
                                </IconButton>
                            </Box> 
                        }
                    </> :
                    <Box sx={{ml: 'auto', display: 'flex', alignItems: 'center', columnGap: 1}}>
                        <Box sx={{display: 'flex', gap: 2, alignItems: 'center'}}>
                            <Box>
                                {/* FIXME: Too long username breaks UI */}
                                {user?.isLogined && <h3>{user.username}</h3>}
                            </Box>
                            {
                                icon && 
                                <Box>
                                    { icon }
                                </Box>
                            }
                        </Box>
                        <Box>
                            {!user?.isLogined && <LoginButton/>}
                        </Box>
                        <Box>
                            <IconButton onClick={() => dispatch(toggleTheme())} sx={{cursor: 'pointer'}}>
                                { 
                                    theme.palette.mode === 'dark' ?
                                    <LightModeRoundedIcon fontSize='large' /> :
                                    <DarkModeRoundedIcon fontSize='large' />
                                }
                            </IconButton> 
                        </Box>
                        { user?.isLogined &&
                        <Box>
                            <IconButton
                                size="large"
                                aria-owns={anchorEl ? "menu-appbar" : undefined}
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenuClick}
                                color="inherit"
                            >
                                <AccountCircle fontSize='large' />
                            </IconButton>
                            <HeaderMenu 
                                anchorEl={anchorEl}
                                handleClose={handleClose}
                                logout={logout}
                            />
                        </Box>
                        }
                    </Box>
                }
            </Box>
            <Drawer 
                open={openDrawer}
                toggleDrawer={toggleDrawer}
                user={user}
                logout={logout}
            />
        </Container>
    );
}

export default withPather()(Header);